import useI18n from '../../tools/i18n.js'

function Header({ lang, setLang }) {
  const t = useI18n(lang)

  return (
    <>
    <header id="header">
      <div className="topbar fixed top-2 right-8 text-right">
        <div className="language-selector flex flex-col justify-end">
          <button
            onClick={() => setLang('es')}
            className={`language-btn bg-transparent ${lang === 'es' ? 'underline' : ''}`}
          >Español</button>
          <button
            onClick={() => setLang('en')} 
            className={`language-btn bg-transparent ${lang === 'en' ? 'underline' : ''}`}
          >English</button>
        </div>
      </div>
      <h1 className="uppercase">{t.t('itour')}</h1>
      <h3 className="uppercase">{t.t('subtitle')}</h3>
      <p>{t.t('soon')}</p>
    </header>
    </>
  );
}

export default Header;
